// Bootstrapの変数を上書きして変更したい場合は、ここで定義する

@import 'vendor/bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto|Material+Icons');

$key_color: #293a46;

@font-face {
    font-family: 'amaticb';
    src: url('/fonts/amatic-bold-webfont.woff2') format('woff2'),
         url('/fonts/amatic-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'mallinoregular';
    src: url('/fonts/mallino-webfont.woff2') format('woff2'),
         url('/fonts/mallino-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  vertical-align: middle;
}


/* アイコンサイズ */

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}


/* 背景が明るいとき用のアイコン色 */

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

// .material-icons.md-dark.md-inactive {
//   color: rgba(0, 0, 0, 0.26);
// }


/* 背景が暗いとき用のアイコン色 */

.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

// .material-icons.md-light.md-inactive {
//   color: rgba(255, 255, 255, 0.3);
// }


// 独自のスタイルの定義はここで定義する

/* スマートフォン */
@media screen and (max-width: 767px){
  .isSmart {
    display: none;
    width: 1px;
  }
}

/* タブレット */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .isTablet {
    display: none;
    width: 2px;
  }
}

/* デスクトップ */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .isDesktop {
    display: none;
    width: 3px;
  }
}


body {
    font-family: "Roboto","Hiragino Kaku Gothic ProN",Meiryo,sans-serif;
}

.nowrap{
  white-space: nowrap;
}

.cartcount {
  position: absolute;
  width: 18px;
  height: 18px;
  font-size: 10px;
  color: #fff;
  border-radius: 15px;
  top: 3px;
  right: 10px;
  background: #e73656;
  text-align: center;
  line-height: 17px;
  font-family: helvetica;
}

.navbar{
  border-radius: 0;
  background-color: #ECECE9;
  border: 0;
  color: #fff;
  a{
    // color: #fff;
  }
  .active{
      background: transparent !important;
  }
  .container{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
      background: transparent !important;
}

.dropdown{
  display: none !important;
}

.dropdown a{
  background-color: transparent !important;
}

.navbar-nav > li > .dropdown-menu{
  background-color: #333 !important;
  a{
    color: #fff;
  }
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
    background-color: transparent !important;
}

.navbar-nav > li > .dropdown-menu{
  background-color: #3f3c56 !important;
}

.navbar-default a{
  color: $key_color !important;
}

.navbar-default {
  .material-icons{
    color: $key_color;
  }
}

#topheader {
  .navbar-brand{
    display: none;
  }
}

.navbar-default .navbar-brand{
    font-family: mallinoregular;
    font-size: 60px;
    margin-top: 15px;
    margin-left: 15px;
    color: #fff;
    margin-top: 10px;

}

// .navbar-nav a{
//   color: #fff !important;
// }

.nav > li > a:hover, .nav > li > a:focus{
  background-color: transparent !important;
}

.bg{
      background-image: url(/images/common/vertical_type.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    background-position-y: 114px;
    background-color: #ecece9;
    // height: 700px;
    left: 0;
    width: 100%;
    // z-index: 10;
    p.price{
      font-size: 20px;
      color: #3f3c55;
      strong{
        font-size: 28px;
        color: #FF0079;
        font-weight: 600;
      }
      .campaign{
        font-size: 12px;
      }
    }
}



#errmsg{
  color: #ff3300;
}

.header-msg {
  position: relative;
  top: 6em;
  left: 14em;
  text-align: center;
  width: 135px;
}

.starburst {
    display: none;
    width: 6em;
    height: 6em;
    background: #fe0;
    -webkit-transform: rotate(-82deg);
    -moz-transform: rotate(-45deg);
    rotation: -45deg;
    position: relative;
    top: 8em;
    left: 14em;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.starburst span {
  display:block;
  width:6em;
  height:6em;
  background:#fe0;
  -webkit-transform:rotate(22.5deg);
  -moz-transform:rotate(22.5deg);
  rotation:22.5deg;
}

.bgbottom{
  // background-size: cover;
  background-image: url('/images/common/header_image_baby.png');
  background-size: cover;
  // background-attachment:fixed;
  position: relative;
  background-position: center;
  height: 380px;
  .logo{
    padding-top: 100px;
    .price {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      text-shadow: 0 0 10px #000;
    }
  }
}

.logo{
    p.price{
      font-size: 20px;
    }
}

.announce {
  visibility: hidden;
  height: 10px;
  i {
    color: #FF0079;
  }
  h3{
    color: #FF8781;
  }
}

.checklist{
  margin-top: 2em;
}

#topheader {
  nav {
    // height:   1px !important;
  }
  .is-show {
  .navbar-brand {
    display: block;
  }
  transform: translateY(0);
  }
}

.clone-nav {
  background-color: #000 !important;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 20px;
  transition: .3s;
  transform: translateY(-100%);
}

.is-show {
  .navbar-brand {
    display: block;
  }
  transform: translateY(0);
}


.logo{
      margin: 0px 15px 15px 15px;
    max-width: 1000px;
    /* margin: auto; */
    text-align: center;
    img{
          -webkit-filter: drop-shadow(0px 1px 0px #9E9E9E);
    }
    h1{
    color: #3f3c55;
    font-family: mallinoregular;
    font-size: 136px;
    margin-top: -20px;
    }
}

.faq-list dt {
    cursor: pointer;
    margin-bottom: 15px;
    position: relative;
    margin-left: 10px;
    padding-left: 2em;
    text-indent: -2em;
}

#faq .layer{
  background-color: #E1E8ED;
}


.faqs dt{
    font-weight: bold;
    padding: 5px 10px;
    background: #3f3c56;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #fff;
}
.faqs dt:hover{
    cursor: pointer;
}
.faqs dd{
    padding: 0 0 10px 30px;
    line-height: 1.7em;
}

@media print {

  .sheetprint {
    zoom: 72%;
  }

  h2 {
    -webkit-print-color-adjust: exact;
  }

 a[href]:after {
 content: "" !important;
 }

 abbr[title]:after {
 content: "" !important;
 }
 .printchecksheet{
  display: none;
 }
}

.layer {
  text-align: center;
    h2{
      color: #607D8B;
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 17px !important;
      position: relative;
      top: -150px;
      font-family: serif;
      z-index: 100;
    }
    a{
      font-size: 30px;
    }
}


.catchbutton {
  text-align: center;
    h2{
      color: #3f3c55;
      font-size: 24px !important;
    }
    a{
      font-size: 30px;
    }
}


#about{
  background-size: cover;
  background-image: url('/images/common/background_half.png');
  background-attachment:fixed;
  position: relative;
  padding: 0 !important;
  .caption p{
    text-align: left;
  }
}

.layer2 {
  text-align: center;
    background-color: rgba(70, 98, 125, 0.91);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 100px 0;
    h2{
      margin-bottom: 2em;
    }
}

.pages{
  padding-bottom: 20px;
  h1{
    margin-bottom: 40px;
  }
  h2{
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }
  table{
    float: left;
  }
  p{
    clear: both;
  }
}

.table {
  width: 100%;
  table-layout: auto;
  word-wrap: break-word;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  .panel{
    height: 90%;
  }
}

.add-on .input-group-btn > .btn {
  border-left-width:0;left:-2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
/* stop the glowing blue shadow */
.add-on .form-control:focus {
 box-shadow:none;
 -webkit-box-shadow:none;
 border-color:#cccccc;
}

.input-group {
  width: 100%;
}

.panel-heading{
  h2{
    margin: 0;
    font-size: 1.2em;
  }
}

.equal > div[class*='col-'] {
  display: inline-block;
  flex-direction: column;
}

#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;

}

.bd-footer-links {
    padding-left: 0;
    margin-bottom: 1rem;
    li{
      display: inline-block;
      margin-right: 1em;
      a{
        color: #fff;
      }
    }
}

.radios{
  // margin-bottom: 25px;
}
.radios .radio {
    border: 1px solid #3f3c56;
    display: inline-block;
    width: 100%;
    /* height: 120px; */
    // margin: 10px 5px;
    text-align: center;
    padding: 15px;
    /* font-size: 25px; */
    border-radius: 3px;
    margin-bottom: 20px;
}

.radios input[type=radio]{
    display:none
}

.radios input[type=radio]:checked + .radio{
    background-color:#3f3c56;
    color: #fff;
}

.selectfonts .radio{
  font-size: 30px;
  letter-spacing: 5px;
    overflow: hidden;
    padding: 30px;
}

.swal2-content {
  text-align: left !important;
  font-size: 12px !important;
}

.swal2-title{
  font-size: 1.5em !important;
}

.swal2-icon{
  display: none !important;
}

.caption {
    h3{
    font-size: 18px !important;
    text-align: center;
    font-weight: 600;
  }
}
#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .caption {
  background: rgba(24, 188, 156, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}
#portfolio .portfolio-item .portfolio-link .caption:hover {
  opacity: 1;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
  margin-top: -12px;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content h3,
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
  margin: 0;
}
#portfolio * {
  z-index: 2;
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
  .layer {
    h2{
      top: 0;
    }
  }
  .topblank {
    height: 700px;
  }

  .bg {
    height: 700px;;
  }
}


.btn-outline {
  color: white;
  font-size: 20px;
  border: solid 2px white;
  background: transparent;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: #18BC9C;
  background: white;
  border: solid 2px white;
}
.btn-primary {
  color: white;
  background-color: #2C3E50;
  border-color: #2C3E50;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #1a242f;
  border-color: #161f29;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #2C3E50;
  border-color: #2C3E50;
}
.btn-primary .badge {
  color: #2C3E50;
  background-color: white;
}

.btn-success {
  color: #170e03;
  background-color: #ed9220;
  border-color: #ca7710;
  font-weight: 700;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: white;
  background-color: #128f76;
  border-color: #11866f;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #18BC9C;
  border-color: #18BC9C;
}
.btn-success .badge {
  color: #18BC9C;
  background-color: white;
}
.btn-social {
  display: inline-block;
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 45px;
}
.btn:focus,
.btn:active,
.btn.active {
  outline: none;
}

.navbar {
  z-index: 10;
  height: 20px;
  border-radius: 0;
  background-color: #ECECE9 !important;
  border: 0;
  color: #fff;
  margin-bottom: 0 !important;
  a {
    color: #fff;
    padding: 10px !important;
    &:hover{
      text-decoration: none !important;
    }
  }
  .active {
    background: transparent !important;
  }
  .container {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navbar {
  .admin{
    background-color: $key_color;
  }
}

.navbar-custom {
  background: #2C3E50;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
}
.navbar-custom a:focus {
  outline: none;
}
.navbar-custom .navbar-brand {
  color: white;
}
.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus,
.navbar-custom .navbar-brand:active,
.navbar-custom .navbar-brand.active {
  color: white;
}
.navbar-custom .navbar-nav {
  letter-spacing: 1px;
}
.navbar-custom .navbar-nav li a {
  color: white;
}
.navbar-custom .navbar-nav li a:hover {
  color: #18BC9C;
  outline: none;
}
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
  color: white;
}
.navbar-custom .navbar-nav li.active a {
  color: white;
  background: #18BC9C;
}
.navbar-custom .navbar-nav li.active a:hover,
.navbar-custom .navbar-nav li.active a:focus,
.navbar-custom .navbar-nav li.active a:active {
  color: white;
  background: #18BC9C;
}
.navbar-custom .navbar-toggle {
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  border-color: white;
}
.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #18BC9C;
  color: white;
  border-color: #18BC9C;
}

.sm-right-side {
  margin-top: 5px;
}

.removeitem {
  text-align: left;
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  /* 768px以上用（タブレット用）の記述 */
  .inst_list_item {
    img {
      width: 220px !important;
    }
  }
  .sm-right-side {
    display: none;
  }
  .logo h1 span{
    margin-top: -560px !important;
  }
  .navbar-custom {
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
  }
  .navbar-custom .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .navbar-custom.affix {
    padding: 10px 0;
  }
  .navbar-custom.affix .navbar-brand {
    font-size: 1.5em;
  }
}
section.feature {
  padding: 62px 0 0 0;
}
section.feature h2 {
  font-size: 2.4em;
  font-weight: bold;
  letter-spacing: -0.04em;
  color: #666;
}
section.success {
  background: #18BC9C;
  color: white;
}
@media (max-width: 767px) {
  section.feature {
    padding: 0;
  }
  section.first {
    padding-top: 75px;
  }
}

#footer{
  width: 100%;
  .btndivs{
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .btndiv,form{
    display: inline;
  }
}

#footer h3 {
  margin-bottom: 30px;
}
#footer .footer-above {
  padding-top: 50px;
  background-color: #2C3E50;
}
#footer .footer-col {
  margin-bottom: 50px;
}
#footer .footer-below {
  padding: 20px 0;
  background-color: #ddd;
  border-top: 1px solid #ccc;
}

footer h3 {
  margin-bottom: 30px;
}
footer .footer-above {
  padding-top: 50px;
  background-color: #2C3E50;
}
footer .footer-col {
  margin-bottom: 50px;
}
footer .footer-below {
  padding: 20px 0;
  background-color: #233140;
}

.security{
  img{
  width: 50px;
  display: inline-block;
  margin-right: 10px;
  }
  p{
    font-size: 11px;
    color: #fff;
  }
}

hr.star-light,
hr.star-primary {
  padding: 0;
  border: none;
  border-top: solid 5px;
  text-align: center;
  max-width: 250px;
  margin: 25px auto 30px;
}
hr.star-light:after,
hr.star-primary:after {
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.star-light {
  border-color: white;
}
hr.star-light:after {
  background-color: #18BC9C;
  color: white;
}
hr.star-primary {
  border-color: #2C3E50;
}
hr.star-primary:after {
  background-color: white;
  color: #2C3E50;
}
.toc{
  margin-top: 20px;
}
.uploaded {
  text-align: center;
  display: block;
  margin: auto;
  position: relative;
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
}

.uploaded_loading{
  position: relative;
  top: 90px;
  display: block;
  margin: auto;
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}
.bg_calendar{
    // height: 400px;
    border: 4px solid #eee;
    /* height: 120%; */
    background-position: center;
    background-color: #eee;
    /* margin: 20px; */
    /* border: 1px solid #000; */
    background-size: contain;
    background-repeat: no-repeat;
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .logo{
    h1{
      font-size: 11em;
      margin-top: 28px;
      span {
          font-size: 15px;
          display: block;
          margin-top: -60px;
      }
    }
  }

  .inst_list_item {
    img {
      width: 350px;
    }
  }

  .bg {
    height: 500px;
  }

  .topblank {
    height: 520px;
  }

  .announce{

  }

  .bottom_btn a,
  .bottom_btn button{
    width: 100%;
  }

  .form-control{
    height: auto !important;
  }

  .photoarea{
    margin: 0;
  }
  // .photo{
  //   padding:0 40px 10px 40px;
  // }
  .calendararea{
    margin-bottom: 10px;
    padding: 0;
  }

  #footer input, #footer a{
    margin: 4px !important;
    padding:6px !important;
  }

  .modal-lg{
  width: 95% !important;
}

#uifooter{
  background-color: #293a46;
  position: fixed;
  bottom: 0;
  margin-top: 20px;
  padding: 10px;
  display: table;
  width: 100%;
  z-index: 10;
  .btndivs{
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .btndiv,form{
    display: inline;
  }
}


}

.payment{
  .row{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .logo{
    h1{
      font-size: 30em;
    }
  }

  .starburst{
    top: -35em;
    left: -6em;
  }

  .catchbutton{

        margin-top: -350px;
        padding-bottom: 150px;
  }

  .photoarea{
    // margin-top: 40px;
  }

  .calendararea {
      padding:60px 35px 40px 35px;
  }

  .modal-lg{
  width: 40% !important;
}


}


.calendararea {
    border: 1px solid #ccc;
    overflow: hidden;
    margin: auto;
    margin-bottom: 50px;
    box-shadow: 2mm 2mm 10mm 0 rgba(0, 0, 0, .1), .2mm .2mm .4mm .1mm rgba(0, 0, 0, .1);
}

.img-responsive{
  width: 100%;
}

.modal-backdrop{
  z-index: 1040 !important;
}


.title span{
  font-size: small;
  font-weight: normal;
}

.title form{
  display: inline;
}

a:focus {
    outline: none !important;
    text-decoration: none !important;
}

.modal-backdrop {
  z-index: -1;
}

.fc-event{
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc-time{
  display: none;
}
.fc {
	display: table-cell;
}

.calendararea {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  // display: flex;
}
.calendararea > [class*='col-'] {
   // display: flex;
   flex-direction: column;
}


.fc-day-number {
    cursor: pointer;
    width: 100%;
    display: table;
}

.fc-other-month{
  visibility: hidden;
}

.event-hidden{
  visibility: hidden;
}
.fc-today{
  background-color: transparent !important;
}

.modal-footer,
.modal-header{
  border:0px !important;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number{
  float: none !important;
}

.fc td, .fc th{
	text-align: center;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number{
	font-size: 20px !important;
}

.holiday
{
    border:1px solid #69196c;
    background: #764ead url(holiday.png) 50% top repeat-x;
    color: white;
}


.fc-title {
  font-size: 9px !important;
  white-space: normal !important;
    line-height: 10px;
    display: inherit;
    margin-bottom: 10px;
}

.fc-title:hover{
  cursor: pointer;
}

.caltitle {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
  span{
    font-size: 40px;
    margin:0 20px;
  }
}

.fc-scroller, .fc-day-grid-container{
  height: auto!important;
}

.fc td, .fc th {
  height: 32px;
  border: 0px;
  border-width:0px !important;
}

.fc-head {
  height: 50px !important;
  display: table-cell;
}

// .cropper-view-box,
// .cropper-face {
//   border-radius: 50%;
// }


// 管理画面

.admin h1{
  font-size: 20px;
}

.admin .container {
    width: 95% !important;
}

.table {
  background-color: #fff;
}

.table td a {
  cursor: pointer
}
fieldset#anotheraddress{
  // display: none;
}

.btn-group{
  margin-bottom: 10px;
}

.admin form{
  display: inline;
}

.admin input{
  display: inline;
}

.actionbutton{
  margin-bottom: 10px;
}

#receipt{
  h4{
    display: block;
    width: 100%;
  }
  table{
    margin: 20px 0 0 0;
    td.table-image{
      width: 400px;
    }
  }

  .admintable {
    th{
      width: 10em;
    }
  }

  td.right{
    text-align: right;
  }
  h1{
    text-align: center;
    font-size: 18px;
    border: 1px solid;
    padding: 10px;
  }

  h3 {
  position: relative;
  padding-bottom: .5em;
  border-bottom: 4px solid #ccc;
  }

  h3::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: 2;
    content: '';
    width: 20%;
    height: 4px;
    background-color: #3498db;
  }

  h4{
    clear: both;
  }
  .header{
    display: table;
    width: 100%;
    .to_name{
      font-size: 20px;
      display: block;
      margin: 20px 0;
    }
    span {
      display: inline-block;
      border-bottom: 1px solid;
      width: 300px;
      margin-right: 10px;
    }
  }
  .destination{
    display: table-cell;
  }
  .senderinfo{
    width: 350px;
    display: table-cell;
    dl{
      dt{
        float: left;
        clear: left;
      }
      dd{
        float: left;
      }
    }
  }
  .product_detail{
    overflow: hidden;
    .photo{
      float: left;
      min-height: 130px;
      // height: 150px;
      // height: 220px;カスタムが多い場合
      img{
        overflow: hidden;
        float: left;
        border:1px solid #ccc;
      }
      ul{
        width: 120px;
        // width: 200px;カスタムが多い場合
        float: left;
        margin-left: -1em;
        margin-right: 1em;
        font-size: 11px;
      }
    }
  }
  #transaction_date_cancel,
  #delivery_date_cancel,
  #delivery_number_cancel,{
    display: none;
  }

  #transaction_date_update,
  #delivery_date_update,
  #delivery_number_update{
    display: none;
  }

  .receipt_table{
    display: flex;
    flex-wrap: wrap;
  }
}

.flcheckbox {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1.0em;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.flcheckbox .ckbBox {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid #b4b4b4;
  margin-right: 5px;
  background-color: #fff;
}
.flcheckbox .ckbBox:hover {
  border: 2px solid #808080;
}
.flcheckbox.flchecked .ckbBox {
  background: url(/images/common/checkbox-checked.png) left top no-repeat;
}
.flcheckbox .ckbText {
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
}

//カート決済画面
.step-regist {
  display: -ms-flexbox;
    display: flex;
    // -ms-flex-pack: center;
    // justify-content: center;
    font-size: 12px;
    line-height: 1em;
    /* position: relative; */
    /* padding-top: 41px; */
    text-align: center;
    /* color: #333; */
    padding-left: 0;
}


.step-regist li {
    position: relative;
    display: inline-block;
    width: 33%;
    margin: 0px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.step-regist li:first-child{
  border-right: 1px solid #ccc;
}

.step-regist li:last-child{
  border-left: 1px solid #ccc;
}

.step-regist li.active span{
    background-color: orange;
    color: #fff;
  }

.step-regist li p{
  padding-top: 10px;
  height: 4em;
}

.step-regist li span {
    display: inline-block;
    width: 100%;
    height: 30px;
    background: url(/common/v4.1/images/html/old_ico.png?1444889753) no-repeat 0 -894px;
    // position: absolute;
    // top: -41px;
    // left: 50%;
    // margin-left: -15px;
    content: '';
    background: #fff;
   padding-top: 8px;
   border-bottom: 1px solid #ccc;
}


.bottombuttons{
  margin-bottom: 2em;
}

td.price{
  text-align: right;
}

.carttable{
  th{
    white-space: nowrap;
  }
  .table-image{
    width: 60%;
    padding-left: 0;
    padding-right: 0;
  }
}

.bottom_btn{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
}

.bottom_btn a,
.bottom_btn button{
  padding: 10px;
  font-weight: 400;
  margin-top: 20px;
}

legend {
    background-color: #c4c3cb;
    color: #3f3c56;
    padding: 5px 0 5px 10px;
    font-size: 12px;
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(63, 60, 86, 0.37);
    border-left: 4px solid rgba(63, 60, 86, 0.37);
    border-top: 1px dotted rgba(63, 60, 86, 0.37);
}

select.form-control {
    height: 2em !important;
}

select {
  font-size:16px;
}

.shareimage{
  img{
    margin-bottom: 10px;
  }
  p{
    margin-bottom: 20px;
  }
}

.currency{
  white-space: nowrap;
  vertical-align: middle !important;
}

.form-horizontal {
  p{
    margin: 5px 0 0 0;
  }
  .well{
    background-color: #fff !important;
  }
  label{
    font-weight: normal;
    input,label{
      font-size: 17px;
      margin-right: 7px;
    }
  }
}

// .form-group{
//   margin: 0 0 25px 0 !important;
// }

.form-group {
  input {
    font-size: 16px;
  }
}

.adbox {
    position: relative;
    background: #ffffff;
    margin: 0 0 1em 0;
    padding: 10px 13px;
    line-height: 1.5;
    dt a{
        font-size: 18px;
        font-weight: normal;
        color: #0033cc;
        text-decoration: underline;
    }
    dd.adtxt {
        font-size: 14px;
        color: #333333;
    }
    dd.adurl {
        font-size: 12px;
        color: #559933;
    }
    em {
        font-style: normal;
        font-weight: bold;
    }
}

ul.share-buttons{
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 100px 0;
  a{
    color: #777;
    margin-right: 20px;
    .fa-2x{
      font-size: 4em;
    }
  }
}

ul.share-buttons li{
  display: inline;
}

ul.share-buttons .sr-only{
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.inst_wrap{
    background: #fff;
    width: 300px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #d5d5d5;
}
.inst_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5% 0;
}
.inst_list_item{
    img {
        background-color: antiquewhite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }
}
.inst_thumb{
    width: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}
.inst_thumb:hover img{
    opacity: .5;
}
.inst_comment{
    font-size: 12px;
    padding: 5%;
    i{
      color: #F74457;
    }
}

.float.right{
  float: right;
  margin: 0 0 1em 1em;
  width: 300px;
}

.float.left{
  float: left;
  margin: 0 1em 1em 0;
  width: 300px;
}

aside h4{
  margin: 0;
  font-size: 14px;
}

.blogdate {
  display: block;
  font-size: 11px;
  color: #293a46;
  margin-top: 1em;
}

article .fa-bookmark {
  margin-left: 10px;
}

article p{
  line-height: 180%;
  margin-bottom: 1.5em;
}

article .body img{
    border: 1px solid #cccccc;
  }

article p a{
  color: #67638e;
  text-decoration: underline;
}

article .lead{
  margin-top: 20px;
  font-size: inherit !important;
}

article h3{
  clear: both;
}

article {
  h1{
    font-size: 30px;
  }
  h3 {
    margin-top: 2em;
    font-size: 20px;
    a{
      color: #4d4d4d;
    }
  }
  h3:before {
    font-family: FontAwesome;
    content: "\f14a\00a0";
    color: #333;
  }
  h2 {
    margin-top: 60px;
    font-weight: 600 !important;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 20px;

    a {
      color: #4d4d4d;
    }
  }
  margin-bottom: 60px;
}


article {
  .hero{
    margin-bottom: 40px;
    display: block;
  }
}

.socialbutton{
  margin-top: 40px;
}

.socialbutton > * {
  vertical-align: bottom;
}

//480px 以上
@media (min-width: 480px) {
.mas {
  img{
    width: 100%;
  }
  margin: 0;
  width: 25%;
  }
  .mas-big {
      width: 50%;
  }

  .bottom_btn a,
  .bottom_btn button{
    width: 49%;
  }

  .bg{
    height: 700px;
  }

  .topblank {
    height: 700px;
  }
}


//480px 以下
@media (max-width: 480px) {
.mas {
  img{
    width: 100%;
  }
  margin: 0;
  width: 50%;
  }
  .mas-big {
      width: 100%;
  }
  .float {
      width: 100% !important;
  }
  article {
    img {
      width: 100%;
    }
  }

}

.toc_title{
  margin-bottom: 0;
}

.ul-toc{
  line-height: 1.5em;
}

#appreach-box {
  max-width: 580px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  line-height: 1.4;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06);
  margin: 40px 0 40px 0;
}

#appreach-box br {
  display: none;
}

#appreach-image {
  margin-right: 15px !important;
  margin-bottom: 0 !important;
}

#appreach-appname {
  color: #3a3a3a;
  font-size: 1.35em;
  font-weight: bold;
}

#appreach-developer, #appreach-price, .appreach-powered {
  color: #888;
  font-size: 11px !important;
}

#appreach-box .appreach-links {
  margin: 10px 10px 0 0;
  display: table;
  div {
    display: table-cell;
    img{
      width: 100px;
    }
  }
}

#appreach-itunes-link {
  padding-right: 10px;
}

#appreach-box .appreach-links img:hover {
  opacity: 0.8;
}


.links {
    margin: 40px 0;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: #ebebeb;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #999;
    padding: 0 0 5px 15px;
}

.linktitle{
  margin-bottom: 0 !important;
  a{
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #999;
    text-decoration: underline;
  }
}

.linkdescription{
  margin-bottom: 0 !important;
  a{
    font-weight: normal;
    line-height: 17px;
    color: #999;
  }
}

.source {
    margin-top: 10px;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #999;
    a{
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #999;
    text-decoration: none;
    }
}

.asideinfoimage {
    background-color: #999;
    display: block;
    text-align: center;
    img{
      padding: 10px;
    }
}

.helpcontents {
  .panel-body {
    ul,ol{
      padding-left: 0;
    }
    li{
      margin-left: 1em;
      margin-bottom: 0.3em;
    }
  }
}

.honbun {
  h1{
    margin: 0.5em 0;
  }
  h3{
    margin-top: 2em;
  }
  h4 {
    margin-top: 1em;
  }
  img{
    margin-bottom: 2em;
    border: 1px solid #999;
    max-width: 345px;
  }
  ol,ul{
    padding-left: 0.3em;
    margin-top: 1em;
  }
  li{
    margin-left: 1em;
  }
}


.list-box {
  display: table;
  margin: 10px 0 10px 0;
  height: 85px;
}

.list-img {
  width: 50px;
  height: 50px;
  display: table-cell;
  vertical-align: top;
  div {
    width: 50px;
    height: 50px;
    background-size: cover;
  }
}

.list-text {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
  h4 {
    margin-top: 0;
  }
}

.list-text {
  font-size: 14px !important;
}

.list-box a {
  color: #293a46;
}

.blogdate{
  font-size: 11px;
}

.pdf{
  background-position: center;
  background-image: url('/images/common/loading.gif');
  background-repeat: no-repeat;
}

.photo {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.crop_button{
  border-width:1;
  color: #999;
  display: inline-block !important;
  text-align: left;
  margin-bottom: 5px;
}

.uploaddone{
  border-width:1;
  color: #999;
  text-align: left;
  margin-bottom: 5px;
  visibility: hidden;
}


.breadcrumb {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0 !important;
  background-color: transparent !important;
}

.breadcrumb li{
  display:inline;/*横に並ぶように*/
  list-style: none;
}

.breadcrumb li:after{/* ▶を表示*/
  font-family: FontAwesome;
  content: '\f0da';
  padding: 0 3px;
  color: silver;
}

.breadcrumb li:last-child:after{
  content: '';
}

.breadcrumb li a {
    text-decoration: none;
    color: #888;
}

.breadcrumb li:first-child a:before{/*家アイコン*/
  font-family: FontAwesome;
  content: '\f015';
  font-weight: normal;
  font-size: 1.1em;
  color: $key_color;
}

.breadcrumb li a:hover {
    text-decoration: underline;
}

.breadcrumb > li + li:before {
    content: "" !important;
    padding: 0px;
}

.row-header {
  padding: 20px 0;
  h1{
    padding: 10px 0;
  }
}

.docs-buttons {
  img {
    width: 100%;
  }
}


.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  padding: 10px;
  position: absolute;
  background-color: $key_color;
  width: 200px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translateY(-0.3em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  li {
    margin: 10px;
    list-style-type: none;
  }
}

.dropdown-mypage:hover .dropdown-content-mypage {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.dropdown-content-mypage {
  visibility: hidden;
  opacity: 0;
  padding: 10px;
  position: absolute;
  width: 200px;
  left: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translateY(-0.3em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  li {
    margin: 10px;
    list-style-type: none;
  }
}

.hiraku-open-btn {
  display: none;
  padding: 0 !important;
  border: 0px !important;
  margin: 10px;
  float: left;
  .material-icons {
    font-size: 30px;
    border: 0px;
  }
}

.hiraku-open-btn-line {
  margin: 13px 0 !important;
  &:before{
    background-color: $key_color !important;
  }
  &:after {
    background-color: $key_color !important;
  }
  background-color: $key_color !important;
}

.sm-auth,
.sm-login {
  position: absolute;
  right: 55px;
  padding-right: 20px;
  padding-top: 10px;
}

.sm-help {
  position: absolute;
  right: 43px;
  padding-top: 10px;
}

.sm-cart {
  position: absolute;
  right: 0;
  padding-right: 10px;
  padding-top: 10px;
}

.offcanvas-left {
  display: none;
}

.offcanvas-left-mypage {
  display: none;
}

.logo_image_loading {
  position: relative;
  display: block;
  margin: auto;
  top: 258px;
}

/* The customcheck */

.checkbox-inline {
  padding-left: 0 !important;
}

.customcheck {
  display: block;
  position: relative;
  padding-left: 30px !important;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
  border:2px solid #999;
}

/* On mouse-over, add a grey background color */
.customcheck:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customcheck input:checked~.checkmark {
  background-color: #333;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customcheck .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#campaignfield {
      padding: 20px 0 40px 0;
      h4{
        background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
        padding: 10px;
        color: #fff;
        border-radius: 5px;
        i{
          font-size: 140%;
          padding-right: 5px;
          font-weight: 100;
        }
      }
      .checkbox-inline {
        padding: 0;
      }
}

.campagin {
  color: #FF3A48;
  font-weight: 600;
  font-size: 110%;
}

.info-list {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  span {
    margin-right: 10px;
  }
  a {
    font-weight: bold;
  }
}

.portfolio-item {
  img {
    margin: auto;
    border-radius: 900px;
    object-fit: cover;
    width: 200px;
    height: 200px;
    box-shadow: 0 0 0 3px pink;
    margin: 10px auto 20px auto;
  }
  margin-bottom: 120px;
}

.feature {
  h3{
    color: #2C3E50;
  }
}

.carousel-cell {
  width: 100%;
  height: 234px;
  border-radius: 5px;
  counter-increment: carousel-cell;
}

@media screen and (min-width: 768px) {
  .carousel-cell {
    height: 412px;
  }

 .header-msg{
   display: none;
 }
}

.closed {
  ackground: #eee;
  border: 1px solid #999;
  padding: 15px;
}